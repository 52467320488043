<template>
  <Layout>
   

    <div class="row">
      <div class="col-lg-12">
        <div class="card border border-radius-12">
          <div class="card-body table">
            <div
              class="main_header_ctas d-flex gap-2 flex-wrap align-items-center justify-content-between py-2"
            >
              <!-- <div :class="{
               'del_btn-wrapper': true,
                'border-orange rounded-pill p1': multipuleIds.length >= 1
                    }">
                <button
                  v-if="multipuleIds.length >= 1"
                  @click="deleteBulk"
                  class="btn border-0 px-5 fw-bold text-white btn btn-primary waves-effect waves-light w-md primary-button rounded-pill"
                >
                  Delete All
                </button>
              </div> -->
              <div class="d-flex gap-2 align-items-center"> 
                <PageHeader title="Location Type Listing" class="poppins fw-normal pt-3 ps-3" />
               <div class=""> 
                <searchInput @getSearched="handleSearch"/>
              </div>
              </div>
             
              <div class="add_new border-orange rounded-pill p1">
                <router-link
                  to="/location-type/add"
                  class="btn btn-primary border-0 px-5 fw-bold text-white btn btn-primary waves-effect waves-light w-md primary-button rounded-pill"
                >
                  Add New
                </router-link>
              </div>
            </div>
            <!-- <div class="table-responsive">
              <table
                id="datatable"
                class="table table-centered table-nowrap mb-0"
              >
                <thead class="table-light bg-transparent">
                  <tr>
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input
                          @change="selectAllRecord"
                          v-model="selectAll"
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck1"
                        />
                        <label class="form-check-label" for="customCheck1"
                          >&nbsp;</label
                        >
                      </div>
                    </th>
                    <th>Location Type Title</th>
                    <th>Location Type Slug</th>   
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading" class="text-center">
                    <td colspan="4">
                      <APILoader
                        :loading="loading"
                        class="align-middle text-center"
                      ></APILoader>
                    </td>
                  </tr>
                  <tr
                    v-else
                    v-for="(locationType, index) in locationTypes"
                    :key="index"
                  >
                    <th style="width: 20px">
                      <div class="form-check font-size-16">
                        <input
                          v-model="multipuleIds"
                          :value="locationType.id"
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck1"
                        />
                        <label class="form-check-label" for="customCheck1"
                          >&nbsp;</label
                        >
                      </div>
                    </th>
                    <td>
                      <span>
                        <img :src="locationType.icon" alt="location type icon" height="32px" width="32px">
                        {{ locationType.title }}
                      </span>
                    </td>
                    <td>{{ locationType.slug }}</td>
                    
                    <td class="table-icon">
                      <div class="d-flex">
                        <button
                          style="margin: 10px"
                          @click="locationTypeUpdate(locationType.id)"
                          class="p-0 bg-transparent border-0"
                        >
                          <span class="text-primary">
                            <i class="uil-edit"></i>
                          </span>
                        </button>
                        <button
                          @click="deleteLocationType(locationType.id)"
                          class="p-0 bg-transparent border-0"
                        >
                          <span class="text-danger">
                            <i class="uil-trash-alt"></i>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
            <!-- end table-responsive -->
            <GeneralTable 
            :isProcessing="processing"
            :items="locationTypes" :fields="fields" 
            @editItem="locationTypeUpdate"
            @deleteItem="deleteUser"
        ></GeneralTable>
         
         
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import Swal from "sweetalert2";
import GeneralTable from "../../../../components/MagicTable/GeneralTable.vue";
import searchInput from "../../../../components/general/searchInput.vue"


export default {
  name: "sub-category",
  components: {
    Layout,
    PageHeader,
    GeneralTable,
    searchInput,
  },
  data() {
    return {
      fields: [
        { key: 'select', label: 'Select', sortable: false, },  
        { key: 'id'},
        { key: 'avatar_with_title', label: 'Location Type', },
        { key: 'created_at',tdClass:'align-middle'},
        { key: 'updated_at', label: 'Last Updated',tdClass:'align-middle'},
        { key: 'action',tdClass:'align-middle'},
      ],
      multipuleIds: [],
      selectAll: false,
      title: "Sub Category Listing",
      loading: true,
      locationTypes: null,
      processing: false,
    };
  },
  mounted() {
    this.allLocationType({});
    this.processing = true;
  },
  methods: {
    deleteUser(id) {
      let apiUrl = `location-type/`;
           let confirmationMessage = "Location deleted successfully."
           this.processing = true;
           this.deleteIt(apiUrl, id,confirmationMessage)
           .then(() => {
            this.processing = false;
            }).catch(error => {
                console.error('Error deleting job:', error);
                this.processing = false;
            });
      console.log('Received ID from child:', id);
    },
     formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); 
},
    randerData(response) {
  let locationTypes = Array.isArray(response.data.data) && response.data.data?.length > 0 
    ? response.data.data 
    : [];
  this.locationTypes = locationTypes.map(location => ({
    id: location.id,
    avatar: location.icon,
    name: location.title,
    slug: location.slug,
    created_at: this.formatDate(location.created_at), 
  updated_at: this.formatDate(location.updated_at),
  }));
  this.processing = false;
  console.log(this.locationTypes, "formatted locationTypes");

      // if ($.fn.dataTable.isDataTable("#datatable")) {
      //   let table = $("#datatable").DataTable();
      //   table.destroy();
      // }
      // setTimeout(() => {
      //   $("#datatable").DataTable({
      //     ordering: false,
      //     lengthMenu: [
      //       [5, 10, 25, 50, -1],
      //       [5, 10, 25, 50, "All"],
      //     ],
      //     pageLength: 25,
      //   });
      // });
    },
    allLocationType({searchItem=''}) {
      console.log( searchItem,"handle search")

      let endPoint = "location-type";
     
      this.$axios
      .get(endPoint, {params:{search: searchItem}})
        .then((response) => {
          setInterval(this.randerData(response), 1500);
          console.log(response.data.data, "then");
        })
        .catch((error) => {
          console.log(error.response);
          this.processing = false;
        });
    },
    async delete(id) {
      try {
        const {
          data: { status },
        } = await this.$axios.delete("location-type/" + id);
        this.responseDelete = status;
      } catch (error) {
        console.log(error);
      }
    },
    deleteLocationType(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.delete(id);
          let status = this.responseDelete;
          if (status == true) {
            Swal.fire("Deleted!", "Record has been deleted.", "success");
            this.allLocationType({});
          }
        }
      });
    },
    locationTypeUpdate: function (id) {
      this.$router.push({
        name: "update-location-type",
        params: { id: id },
      });
    },
    handleSearch(searchItem) {
       this.allLocationType({searchItem});
  },
  
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.locationTypes.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    deleteBulk() {
      this.$axios
        .post("location-type/bulk/delete/records", { ids: this.multipuleIds })
        .then((response) => {
          this.allLocationType({});
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>
